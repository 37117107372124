import ContactUs from '@parameter1/base-cms-marko-web-contact-us/browser';
import MonoRail from '@parameter1/base-cms-marko-web-theme-monorail/browser';

const DynamicSiteMenuPositioner = () => import(/* webpackChunkName: "dynamic-site-menu-positioner" */ './dynamic-site-menu-positioner.vue');

export default (Browser) => {
  ContactUs(Browser);
  MonoRail(Browser);
  Browser.register('DynamicSiteMenuPositioner', DynamicSiteMenuPositioner);
};
